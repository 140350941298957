import * as React from "react"

import PickerScreen from '../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="Are you a designer?"
    options={[
      {
        label: "Yes, I am",
        link: "/app/tool/adobe-photoshop/"
      },
      {
        label: "No, I am not",
        link: "/app/graphic-design/social-media-posts/have-you-used-a-design-tool-before/"
      }
  ]}/>
)

export default Picker;


